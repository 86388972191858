import Vue from 'vue'
import VueRouter from 'vue-router'

// 页面引入
// 系统首页
import Index from '@/views/Index.vue'

// 登录/注册
import Login from '@/views/Login.vue' // 登录页面

// 会员
import MemberList from '@/views/member/List.vue' // 会员列表
import MemberDailiList from '@/views/member/dllist.vue' // 代理列表
import MemberDistribution from '@/views/member/Distribution.vue' // 分销详情
import MemberDistributionRecords from '@/views/member/DistributionRecords.vue' // 分销详情列表数据
import MemberWithdrawal from '@/views/member/Withdrawal.vue' // 提现审核
import MemberWithdrawalList from '@/views/member/WithdrawalList.vue' // 提现列表
import MemberSubscription from '@/views/member/Subscription.vue' // 认缴人员列表
import MemberFinancialRecords from '@/views/member/FinancialRecords.vue' // 财务记录

// 门店
import StoreList from '@/views/store/List.vue' // 门店列表
import StoreTable from '@/views/store/Table.vue' // 门店桌子
import StoreDev from '@/views/store/Devlist.vue' //开发门店
// 商品
import GoodClassifyList from '@/views/good/Classify.vue' // 分类列表
import GoodsMenuList from '@/views/good/Goods.vue' // 菜品列表
import GoodAdd from '@/views/good/Add.vue' // 添加菜品
import SupplyList from '@/views/good/SupplyList.vue'  // 供应链分类列表

// 订单
import OrderList from '@/views/order/List.vue' // 订单管理
import OrderGylList from '@/views/order/gylList.vue' // 订单管理

// 配置
import ConfigAchievement from '@/views/config/achievement.vue' // 业绩收益分佣配置

// 运营
import OperateBanner from '@/views/operate/banner.vue' // 轮播管理
import OperateQrcode from '@/views/qrcode/banner.vue' // 轮播管理
import OperateServer from '@/views/operate/server.vue' // 轮播管理
import AnnouncementServer from '@/views/operate/announcement.vue' // 轮播管理
import CouponServer from '@/views/operate/coupon.vue' // 优惠券管理
import AdvertisementServer from '@/views/operate/advertisement.vue' // 优惠券管理

// 统计
import StatisticsTransaction from '@/views/statistics/Transaction.vue' // 交易统计
import StatisticsDatalist from '@/views/statistics/datalist.vue' // 门店数据
import StatisticsSupply from '@/views/statistics/supply.vue' // 供应链数据
import StatisticsIncome from '@/views/statistics/Income.vue' // 收入概括
import StatisticsExpend from '@/views/statistics/Expend.vue' // 支出概括
import StatisticsCommission from '@/views/statistics/Commission.vue' // 佣金概括
import StatisticsUnissued from '@/views/statistics/Unissued.vue' // 未发放佣金概括

import StatisticsSales from '@/views/statistics/Sales.vue' // 销量统计

// 设置
import SetShelf from '@/views/set/Shelf.vue' // 上架管理
import SetRole from '@/views/set/Role.vue' // 角色管理
import SetUser from '@/views/set/User.vue' // 用户管理
import SetMenu from '@/views/set/Menu.vue' // 菜单管理

// 其他页面
import Password from '@/views/other/Password.vue' // 修改密码

//  过滤重复路径抛出异常
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}

// 错误
const error = [
  // 404页面
  {
    path: '/404',
    name: 'notfound',
    meta: { title: '404' },
    component: () => import('../views/404.vue')
  },
  // 500页面
  {
    path: '/500',
    name: 'error',
    meta: { title: '500' },
    component: () => import('../views/500.vue')
  }
]

// 登录/注册
const login = [
  {
    path: '/login',
    name: 'login',
    meta: { title: '登录', authority: false },
    component: Login
  }
]

// 点单
const good = [
  {
    path: 'good',
    name: 'Good',
    meta: { title: '菜品', authority: true }
  },
  {
    path: 'good/classify',
    name: 'GoodClassifyList',
    meta: { title: '分类管理', authority: true },
    component: GoodClassifyList
  },
  {
    path: 'good/goods',
    name: 'GoodsMenuList',
    meta: { title: '菜品管理', authority: true },
    component: GoodsMenuList
  },
  {
    path: 'good/Goods/Add',
    name: 'GoodAdd',
    meta: { title: '添加菜品', authority: true },
    component: GoodAdd
  },
  {
    path: 'good/supply',
    name: 'SupplyList',
    meta: { title: '商品分类管理', authority: true },
    component: SupplyList
  },
]

// 订单
const order = [
  {
    path: 'order',
    name: 'Order',
    meta: { title: '订单', authority: true }
  },
  {
    path: 'order/list',
    name: 'OrderList',
    meta: { title: '订单管理', authority: true },
    component: OrderList
  },
  {
    path: 'order/gyllist',
    name: 'OrderGylList',
    meta: { title: '供应链订单', authority: true },
    component: OrderGylList
  }
]

// 会员
const member = [
  {
    path: 'member',
    name: 'member',
    meta: { title: '会员', authority: true }
    // redirect: { name: 'memberList' }
  },
  {
    path: 'member/list',
    name: 'memberList',
    meta: { title: '会员列表', authority: true },
    component: MemberList
  },
  {
    path: 'member/dllist',
    name: 'MemberDailiList',
    meta: { title: '代理列表', authority: true },
    component: MemberDailiList
  },
  {
    path: 'member/list/distribution',
    name: 'memberDistribution',
    meta: { title: '分销详情', authority: true },
    component: MemberDistribution
  },
  {
    path: 'member/distributionRecords',
    name: 'memberDistributionRecords',
    meta: { title: '分销明细', authority: true },
    component: MemberDistributionRecords
  },
  {
    path: 'member/withdrawal',
    name: 'memberWithdrawal',
    meta: { title: '提现审核', authority: true },
    component: MemberWithdrawal
  },
  {
    path: 'member/withdrawalList',
    name: 'memberWithdrawalList',
    meta: { title: '提现列表', authority: true },
    component: MemberWithdrawalList
  },
  {
    path: 'member/subscription',
    name: 'memberSubscription',
    meta: { title: '认缴人员', authority: true },
    component: MemberSubscription
  },
  {
    path: 'member/financialrecords',
    name: 'memberFinancialRecords',
    meta: { title: '财务记录', authority: true },
    component: MemberFinancialRecords
  }
]

// 门店
const store = [
  {
    path: 'store',
    name: 'store',
    meta: { title: '门店', authority: true }
    // redirect: { name: 'storeList' }
  },
  {
    path: 'store/list',
    name: 'storeList',
    meta: { title: '门店列表', authority: true },
    component: StoreList
  },
  {
    path: 'store/list/table',
    name: 'storeTable',
    meta: { title: '门店桌子', authority: true },
    component: StoreTable
  },
  {
    path: 'store/devlist',
    name: 'StoreDev',
    meta: { title: '开发门店', authority: true },
    component: StoreDev
  }
]

// 配置
const config = [
  {
    path: 'config',
    name: 'config',
    meta: { title: '配置', authority: true }
  },
  {
    path: 'config/achievement',
    name: 'ConfigAchievement',
    meta: { title: '业绩收益分佣配置', authority: true },
    component: ConfigAchievement
  }
]

// 运营
const operate = [
  {
    path: 'operate',
    name: 'operate',
    meta: { title: '运营', authority: true }
  },
  {
    path: 'operate/banner',
    name: 'OperateBanner',
    meta: { title: '轮播管理', authority: true },
    component: OperateBanner
  },
  {
    path: 'operate/qrcode',
    name: 'qrcodeBackground',
    meta: { title: '二维码背景', authority: true },
    component: OperateQrcode
  },
  {
    path: 'operate/server',
    name: 'server',
    meta: { title: '客服电话', authority: true },
    component: OperateServer
  },
  {
    path: 'operate/announcement',
    name: 'announcement',
    meta: { title: '发布公告', authority: true },
    component: AnnouncementServer
  },
  {
    path: 'operate/coupon',
    name: 'coupon',
    meta: { title: '优惠券', authority: true },
    component: CouponServer
  },
  {
    path: 'operate/advertisement',
    name: 'coupon',
    meta: { title: '菜品广告', authority: true },
    component: AdvertisementServer
  }
]

// 统计
const statistics = [
  {
    path: 'statistics',
    name: 'statistics',
    meta: { title: '统计', authority: true }
  },
  // 2023-09-02
  {
    path: 'statistics/datalist',
    name: 'statisticsDatalist',
    meta: { title: '门店数据', authority: true },
    component: StatisticsDatalist
  },
  {
    path: 'statistics/supply',
    name: 'statisticsSupply',
    meta: { title: '供应链数据', authority: true },
    component: StatisticsSupply
  },
  {
    path: 'statistics/transaction',
    name: 'statisticsTransaction',
    meta: { title: '交易统计', authority: true },
    component: StatisticsTransaction
  },
  {
    path: 'statistics/transaction/income',
    name: 'statisticsIncome',
    meta: { title: '收入概况', authority: true },
    component: StatisticsIncome
  },
  {
    path: 'statistics/transaction/expend',
    name: 'statisticsExpend',
    meta: { title: '支出概况', authority: true },
    component: StatisticsExpend
  },
  {
    path: 'statistics/transaction/commission',
    name: 'statisticsCommission',
    meta: { title: '佣金概况', authority: true },
    component: StatisticsCommission
  },
  {
    path: 'statistics/transaction/unissued',
    name: 'statisticsUnissued',
    meta: { title: '未发放佣金概况', authority: true },
    component: StatisticsUnissued
  },
  {
    path: 'statistics/sales',
    name: 'statisticsSales',
    meta: { title: '销量统计', authority: true },
    component: StatisticsSales
  }
]

// 设置页面
const set = [
  {
    path: 'set',
    name: 'set',
    meta: { title: '设置', authority: true }
    // redirect: { name: 'setRole' },
  },
  {
    path: 'set/shelf',
    name: 'setShelf',
    meta: { title: '上架管理', authority: true },
    component: SetShelf
  },
  {
    path: 'set/role',
    name: 'setRole',
    meta: { title: '角色管理', authority: true },
    component: SetRole
  },
  {
    path: 'set/user',
    name: 'setUser',
    meta: { title: '用户管理', authority: true },
    component: SetUser
  },
  {
    path: 'set/menu',
    name: 'setMenu',
    meta: { title: '菜单管理', authority: true },
    component: SetMenu
  }
]

// 其他页面
const other = [
  {
    path: 'other',
    name: 'other',
    meta: { title: '其他板块', authority: true }
  },
  {
    path: 'other/password',
    name: 'password',
    meta: { title: '修改密码', authority: true },
    component: () => import('@/views/other/Password.vue')
    // component: Password,
  },
  {
    path: 'other/personal',
    name: 'personal',
    meta: { title: '个人中心', authority: true },
    component: () => import('@/views/other/Personal.vue')
  }
]

const routes = [
  {
    path: '/',
    redirect: { name: 'home' }
  },
  // 框架页面
  {
    path: '/admin',
    name: 'home',
    redirect: { name: 'index' },
    component: () => import('../views/Home.vue'),
    children: [
      // 系统首页
      {
        path: 'index',
        name: 'index',
        meta: { title: '首页', authority: true, crumbs: true },
        component: Index
      },
      ...set,
      ...member,
      ...store,
      ...good,
      ...order,
      ...other,
      ...config,
      ...operate,
      ...statistics
    ]
  },
  // 登录/注册
  ...login,
  // 错误页面
  ...error,
  // 重定向
  {
    path: '*',
    redirect: { name: 'notfound' }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// 全局前置守卫
router.beforeEach((to, from, next) => {
  // 页面SEO设置
  to.meta.title && (document.title = to.meta.title)
  to.meta.keywords && document.querySelector('meta[name="keywords"]').setAttribute('content', to.meta.keywords)
  to.meta.description && document.querySelector('meta[name="description"]').setAttribute('content', to.meta.description)

  // 判断页面是否需要授权
  if (to.meta.authority) {
    // 判断是否登录
    if (localStorage.getItem('USER_TOKEN')) {
      // 有权限
      next()
    } else {
      // 无权限
      next({ name: 'login' })
    }
  } else {
    // 无需验证的页面
    next()
  }
})

// 全局后置钩子
router.afterEach((to, from) => {})

Vue.use(VueRouter)

export default router
