<template>
  <!-- 广告列表 -->
  <div>
    <!-- 筛选查询 -->
    <div class="wScreenArea">
      <el-card class="box-card">
        <div class="wScreenCardHeader">
          <span></span>
          <el-button
            type="primary"
            size="mini"
            @click="wFun_openBannerFrame()"
          >添加</el-button>
        </div>
      </el-card>
    </div>
    <!-- 表格数据 -->
    <div class="wTableArea">
      <!-- 订单列表 -->
      <template>
        <!-- 表格 -->
        <el-table
          border
          :data="wTable.wList"
        >
          <el-table-column
            prop="id"
            label="编号"
            align="center"
            width="100"
          >
          </el-table-column>
          <el-table-column
            label="背景图"
            align="center"
          >
            <template slot-scope="scope">
              <el-image
                style="width: 180px; height: 100px"
                fit="cover"
                :src="scope.row.imgUrl"
                :preview-src-list="[scope.row.imgUrl]"
              ></el-image>
            </template>
          </el-table-column>
          <el-table-column
            prop="isUsed"
            label="状态"
            align="center"
          >
            <template slot-scope="scope">
              <div v-if="scope.row.isUsed == 0">禁用</div>
              <div v-else-if="scope.row.isUsed == 1">启用</div>
            </template>
          </el-table-column>
          <el-table-column
            label="操作"
            align="center"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                @click="wFun_openBannerFrame(scope)"
              ><span>编辑</span></el-button>
              <el-button
                type="text"
                @click="wFun_deleteBannerById(scope.row.id)"
              ><span>删除</span></el-button>
            </template>
          </el-table-column>
        </el-table>
      </template>
      <!-- 分页 -->
      <div
        class="wPagination"
      >
      <el-pagination
       @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
              :current-page="pagination.current"
              :page-sizes="pagination.sizes"
              :page-size="pagination.size"
              :total="pagination.total"
              layout="total, sizes, prev, pager, next, jumper"
            >
            </el-pagination>
      </div>
    </div>

    <!-- 新增、修改版banner弹框 -->
    <el-dialog
      :title="wBanner.wForm.wId ? '修改二维码背景图' : '添加二维码背景图'"
      :visible.sync="wBanner.wIsShow"
      :append-to-body="true"
      :close-on-click-modal="false"
    >
      <!-- 表单 -->
      <el-form
        ref="wBannerForm"
        :model="wBanner.wForm"
        label-width="120px"
        @submit.native.prevent
      >
        <el-form-item
          label="上传图片："
          prop="wImage"
          required
        >
          <el-upload
            class="avatar-uploader"
            :action="wFun_getUploadImgPath()"
            :show-file-list="false"
            :before-upload="wFun_beforeUpload_banner"
            :on-success="wFun_success_banner"
            :on-error="wFun_error_banner"
          >
            <el-image
              class="avatar"
              v-if="wBanner.wForm.wImage"
              :src="wBanner.wForm.wImage"
              fit="contain"
            ></el-image>
            <i
              v-else
              class="el-icon-plus avatar-uploader-icon"
            ></i>
          </el-upload>
          <p>推荐尺寸：1242 * 2208</p>
        </el-form-item>
        <el-form-item
          label="是否启用："
          prop="wIsUsed"
        >
          <el-switch
            v-model="wBanner.wForm.wIsUsed"
            active-text="启用"
            inactive-text="禁用"
          >
          </el-switch>
        </el-form-item>
      </el-form>
      <!-- footer -->
      <div slot="footer">
        <el-button @click="wBanner.wIsShow = false">取 消</el-button>
        <el-button
          type="primary"
          @click="wFun_submitBannerInfo"
        >确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getQrBackgroundApi,
  addOperateBannerApi,
  editOperateBannerApi,
  deleteOperateBannerApi,
} from "@/network/api";

export default {
  name: "OperateBanner",
  data() {
    return {
      // 字典数据
      wDic_location: [],
      wDic_jumpType: [],
      // 筛选查询
      wScreen: {
        wLocation: null // 广告位
      },
      // 表格数据
      wTable: {
        wList: []
      },
      // 分页配置
      pagination: {
          current: 1,
          size: 10,
          sizes: [10, 20, 50, 100],
          total: 0,
        },
      // 添加/修改banner弹框
      wBanner: {
        wIsShow: false, // 是否展示
        wForm: {
          wId: null, // id 有值代表
          wLocation: 116, // 广告位
          wJumpType: "0", // 跳转类型
          wImage: null, // 图片
          wIsUsed: true // 禁止使用
        } // 表单数据
      }
    };
  },
  mounted() {
    this.wFun_queryTableData(true);
  },
  computed: {
    // 获取分页配置数据
    wC_layout() {
      return this.$store.state.wPage.wLayout;
    },
    wC_pageSizes() {
      return this.$store.state.wPage.wPageSizes;
    },
    // 获取广告位数据
    wC_location() {
      let [{ wDic_location }, wLocationArr] = [this, []];
      wDic_location.forEach(item => {
        let obj = {};
        obj.label = item.label;
        obj.value = item.value.toString();
        wLocationArr.push(obj);
      });
      return wLocationArr;
    },
    // 获取广告位跳转类型的数据
    wC_jumpType() {
      let [{ wDic_jumpType }, wJumpTypeArr] = [this, []];
      wDic_jumpType.forEach(item => {
        let obj = {};
        obj.label = item.label;
        obj.value = item.value.toString();
        wJumpTypeArr.push(obj);
      });
      return wJumpTypeArr;
    }
  },
  methods: {
    /**
     * wFun_getUploadImgPath
     * 方法-获取图片上传的地址
     */
    wFun_getUploadImgPath() {
      return `${process.env.VUE_APP_URE}/file/uploadFile`;
    },
    /**
     * wFun_beforeUpload_banner
     * 方法-上传文件之前的钩子，参数为上传的文件，若返回 false 或者返回 Promise 且被 reject，则停止上传。
     */
    wFun_beforeUpload_banner(file) {
      const isJPG = file.type === "image/jpeg";
      const isPNG = file.type === "image/png";
      // const isLt10M = file.size / 1024 / 1024 < 10;
      if (!isJPG && !isPNG) {
        this.$message.error("上传图片只能是 JPG 或者 PNG 格式!");
      }
      // if (!isLt10M) {
      //   this.$message.error("上传图片大小不能超过 10MB!");
      // }
      // return (isJPG || isPNG) && isLt10M;
      return isJPG || isPNG;
    },
    /**
     * wFun_success_banner
     * 方法-文件上传成功时的钩子
     */
    wFun_success_banner(response, file, fileList) {
      console.log(response, file, fileList);
      let [{ code: wCode, data: wData }] = [response, file, fileList];
      if (wCode == 200) {
        this.wBanner.wForm.wImage = wData;
      } else {
        this.$message.error("图片上传失败!");
      }
    },
    /**
     * wFun_error_banner
     * 方法-文件上传失败时的钩子
     */
    wFun_error_banner(err, file, fileList) {
      console.log(err, file, fileList);
      this.$message.error("图片上传失败!");
    },
    /**
     * wFun_formScreenSubmit
     * 方法-提交表单数据
     */
    wFun_formScreenSubmit() {
      // 方法-查询表格数据
      this.wFun_queryTableData(true);
    },
    /**
     * wELPageCurrentChange
     * 分页 currentPage 改变时会触发
     */
    wELPageCurrentChange(wP_cPage) {
      this.wTable.wPage = wP_cPage;
      this.$nextTick(() => {
        // 方法-查询表格数据
        this.wFun_queryTableData();
      });
    },
    /**
     * wELPageSizeChange
     * 修改分页条数
     * 分页 pageSize 改变时会触发 - 每页条数
     */
    wELPageSizeChange(wP_cPageSize) {
      this.wTable.wLimit = wP_cPageSize;
      this.$nextTick(() => {
        // 方法-查询表格数据
        this.wFun_queryTableData(true);
      });
    },
    /**
     * wFun_queryTableData
     * 方法-查询表格数据
     * @param wP_truthy 真值,true-初始值,false-加载更多
     */
    wFun_queryTableData(wP_truthy) {
      let [
        wData,
        {
          wScreen: { wLocation },
          wTable: {  wList }
        }
      ] = [{}, this];
      // 广告位
      // if (wLocation) {
      //   wData.location = wLocation;
      // }
      wData.location = 116;
      // 请求函数
      getQrBackgroundApi(this.pagination.current,this.pagination.size)
        .then(res => {
          // 更新数据
          this.wTable.wList = res.records;
          this.pagination.total=res.total
        })
        .catch(err => {});
    },
    /**
     * wFun_deleteBannerById
     * 方法-根据id删除banner
     * @wP_id banner的id
     */
    wFun_deleteBannerById(wP_id) {
      if (!wP_id) {
        this.$message.error("系统繁忙，请稍后再试！");
        return;
      }
      this.$confirm("此操作将永久删除该二维码背景图, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          deleteOperateBannerApi({
            id: wP_id
          })
          .then(res => {
            this.$message.success("删除成功!");
            this.wFun_queryTableData();
          })
          .catch(err => {});
        })
        .catch(() => {});
    },
    /**
     * wFun_openBannerFrame
     * 方法-打开banner弹框
     * @param wP_obj banner信息
     */
    wFun_openBannerFrame(wP_obj) {
      // 打开弹框
      this.wBanner.wIsShow = true;
      this.$nextTick(() => {
        // 表单初始化
        this.$refs["wBannerForm"].resetFields();
        this.wBanner.wForm.wId = null;
        if (wP_obj) {
          // 修改banner，给表单赋值
          let [
            {
              row: {
                id: wId,
                location: wLocation,
                jumpType: wJumpType,
                bannerParams, // 根据跳转类型来，暂时还未开发！
                imgUrl: wImage,
                isUsed: wIsUsed // 是否有效 0-无效 1-有效
              }
            }
          ] = [wP_obj];
          // 更新数据
          this.wBanner.wForm.wId = wId;
          this.wBanner.wForm.wLocation = wLocation.toString();
          this.wBanner.wForm.wJumpType = wJumpType.toString();
          this.wBanner.wForm.wImage = wImage;
          this.wBanner.wForm.wIsUsed = wIsUsed == 1;
        }
      });
    },
    /**
     * wFun_submitBannerInfo
     * 方法-提交banner信息
     */
    wFun_submitBannerInfo() {
      let [
        wData,
        {
          wBanner: { wForm }
        }
      ] = [{}, this];

      wData.imgUrl = wForm.wImage;
      // 是否有效 0-无效 1-有效
      wData.isUsed = wForm.wIsUsed ? 1 : 0;
      wData.location = 116;
      wData.jumpType = '0';
      // 根据id是否存在判断新增或修改
      if (wForm.wId) {
        // 修改
        // banner id
        wData.id = wForm.wId;

        editOperateBannerApi(wData)
          .then(res => {
            this.$message.success("修改成功!");
            // 更新数据
            this.wBanner.wIsShow = false;
            this.wFun_queryTableData();
          })
          .catch(err => {});
      } else {
        // 新增
        addOperateBannerApi(wData)
          .then(res => {
            this.$message.success("添加成功!");
            // 更新数据
            this.wBanner.wIsShow = false;
            this.wFun_queryTableData();
          })
          .catch(err => {});
      }
    },
    handleSizeChange(val){
        this.pagination.size=val
      },
      handleCurrentChange(val){
    this.pagination.current=val
      }
  },
  watch:{
    pagination: {
        handler() {
          this.wFun_queryTableData();
        },
        immediate: true,
        deep: true,
      },
  }
};
</script>

<style lang="less">
@import "@/assets/css/publicStyle.less";
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #ff6321;
}
.avatar-uploader .avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar-uploader .avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>