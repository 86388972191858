<template>
  <!-- 优惠券列表 -->
  <div>
    <!-- 筛选查询 -->
    <div class="wScreenArea">
      <el-card class="box-card">
        <div class="wScreenCardHeader">
          <span></span>
          <el-button type="primary" size="mini" @click="show = true"
            >添加</el-button
          >
        </div>
      </el-card>
    </div>
    <!-- 新增、修改版banner弹框 -->
    <el-dialog
      width="1000px"
      :title="couponsForm.id ? '修改优惠券' : '添加优惠券'"
      :visible.sync="show"
      :append-to-body="true"
      :close-on-click-modal="false"
    >
      <!-- 表单 -->
      <el-form
        :rules="rulesCouponsForm"
        :model="couponsForm"
        ref="couponsForm"
        label-width="120px"
        @submit.native.prevent
      >
        <el-row :gutter="24">
          <el-col :span="12">
            <el-form-item label="优惠券名称" prop="couponName">
              <el-input
                v-model="couponsForm.couponName"
                type="text"
                placeholder="请输入优惠券名称"
                maxlength="30"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="优惠券描述" prop="desp">
              <el-input
                v-model="couponsForm.desp"
                type="textarea"
                placeholder="请输入优惠券描述"
                maxlength="30"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="12">
            <el-form-item label="使用范围" prop="supplyId">
              <el-select
                v-model="couponsForm.supplyId"
                @change="handleSelectChange"
                placeholder="请选择范围"
                clearable
                style="width: 100%"
              >
                <el-option
                  v-for="(item, index) in optionsStore"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="使用商品" prop="usedGoodsId">
              <el-select
                remote
                filterable
                v-model="couponsForm.usedGoodsId"
                :remote-method="remoteMethod"
                placeholder="请选择菜品类型"
                clearable
                :disabled="disabledGoods"
                style="width: 100%"
              >
                <el-option
                  v-for="(item, index) in goodsType"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="24">
          <el-col :span="12">
            <el-form-item label="折扣类型" prop="discountType">
              <el-select
                v-model="couponsForm.discountType"
                placeholder="请选择折扣类型"
                clearable
                style="width: 100%"
              >
                <el-option
                  v-for="(item, index) in discountType"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="折扣" prop="discount">
              <el-input
                v-model="couponsForm.discount"
                type="text"
                placeholder="请输入折扣"
                maxlength="30"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="12">
            <el-form-item label="首页" prop="homePage">
              <el-select
                v-model="couponsForm.homePage"
                placeholder="请选择是否为首页优惠券"
                clearable
                style="width: 100%"
              >
                <el-option
                  v-for="(item, index) in homeType"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="发布数量" prop="number">
              <el-input-number
                v-model="couponsForm.number"
                :min="1"
                label="输入发布数量"
              ></el-input-number>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="12">
            <el-form-item label="优惠券图" prop="imgUrl">
              <el-upload
                ref="mainUpload"
                list-type="picture-card"
                :limit="limitMainImg"
                :file-list="mainFileList"
                :class="{ hide: hideMainUpload }"
                :action="getUploadUrl()"
                :before-upload="uploadBefore"
                :on-success="uploadSuccessMain"
                :on-remove="uploadRemoveMain"
                :show-file-list="true"
              >
                <i class="el-icon-plus"></i>
              </el-upload>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="有效期" prop="expiredDate">
              <el-date-picker
                v-model="couponsForm.expiredDate"
                type="datetime"
                placeholder="选择日期时间"
                @change="formatDate"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="12">
            <el-form-item label="是否启用：" prop="ban">
              <el-switch
                v-model="couponsForm.flag"
                active-color="#ff4949"
                inactive-color="#DCDFE6"
                active-value="0"
                inactive-value="-1"
              >
              </el-switch>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <!-- footer -->
      <div slot="footer">
        <el-button @click="show = false">取 消</el-button>
        <el-button type="primary" @click="submitCoupon('couponsForm')"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <!-- 表格数据 -->
    <div class="wTableArea">
      <!-- 订单列表 -->
      <template>
        <!-- 表格 -->
        <el-table border :data="couponsList">
          <el-table-column
            prop="couponName"
            label="名称"
            align="center"
            width="100"
          >
          </el-table-column>
          <el-table-column label="图片" align="center">
            <template slot-scope="scope">
              <el-image
                style="width: 180px; height: 100px"
                fit="cover"
                :src="scope.row.imgUrl"
                :preview-src-list="[scope.row.imgUrl]"
              ></el-image>
            </template>
          </el-table-column>
          <el-table-column prop="desp" label="描述" align="center" width="100">
          </el-table-column>
          <el-table-column prop="homePage" label="首页广告" align="center">
            <template slot-scope="scope">
              <div v-if="scope.row.homePage == 1">首页广告</div>
              <div v-else-if="scope.row.homePage == 0">非首页广告</div>
            </template>
          </el-table-column>
          <el-table-column
            prop="number"
            label="发放数量"
            align="center"
            width="100"
          >
          </el-table-column>
          <el-table-column
            prop="receivedNumber"
            label="已领取数量"
            align="center"
            width="100"
          >
          </el-table-column>
          <el-table-column
            prop="usedLimit"
            label="使用范围"
            align="center"
            width="100"
          >
          </el-table-column>
          <el-table-column
            prop="expiredDate"
            label="有效期"
            align="center"
            width="100"
          >
          </el-table-column>
          <el-table-column prop="discount" label="折扣" align="center">
            <template slot-scope="scope">
              <div v-if="scope.row.discountType == 1">
                {{ scope.row.discount }}折
              </div>
              <div v-else-if="scope.row.discountType == 2">
                {{ scope.row.discount }}
              </div>
              <div v-else-if="scope.row.discountType == 3">
                立减{{ scope.row.discount }}元
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="isUsed" label="状态" align="center">
            <template slot-scope="scope">
              <div v-if="scope.row.flag == -1">禁用</div>
              <div v-else-if="scope.row.flag == 0">启用</div>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <el-button type="text" @click="editCoupon(scope)"
                ><span>编辑</span></el-button
              >
              <el-button type="text" @click="delCoupon(scope.row.id)"
                ><span>删除</span></el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </template>
      <!-- 分页 -->
      <div class="wPagination">
        <div class="wPagination">
          <el-pagination
           @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
            :current-page="pagination.current"
            :page-sizes="pagination.sizes"
            :page-size="pagination.size"
            :total="pagination.total"
            layout="total, sizes, prev, pager, next, jumper"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- 分页 -->
  </div>
</template>

<script>
import {
  getAllUserCouponsApi,
  getAllSupplyGoodsApi,
  getGoodsByNameApi,
  addCouponsApi,
  delCouponApi,
  putCouponApi,
} from "@/network/api";

export default {
  name: "OperateCoupon",
  data() {
    var checkDiscount = (rule, value, callback) => {
      const { discountType } = this.couponsForm;
      if (discountType == 1 || discountType == 3) {
        // Ensure that the value is a number
        const isNumber = /^\d+$/.test(value);
        if (!value) {
          return callback(new Error("折扣不能为空"));
        }
        if (!isNumber) {
          return callback(new Error("折扣必须为数字"));
        }
      } else if (discountType == 2) {
        // Ensure value is in the format "满100减20"
        const isValidFormat = /^满(\d+)减(\d+)$/.test(value);
        if (!value) {
          return callback(new Error("折扣不能为空"));
        }
        if (!isValidFormat) {
          return callback(new Error("折扣必须符合“满100减20”的格式"));
        }
      }
      callback();
    };
    return {
      couponsList: [],
      couponsForm: {
        id: null,
        couponName: "",
        desp: "",
        discountType: "",
        discount: "",
        number: 1,
        receivedNumber: 0,
        expiredDate: "",
        supplyId: "",
        homePage: "",
        usedLimit: "",
        flag: "0",
        usedGoodsId: null,
        imgUrl: null,
      },
      // 优惠券可使用类型options
      optionsStore: [],
      // 优惠券所属菜品
      goodsType: [],
      discountType: [
        {
          label: "折扣券",
          value: 1,
        },
        {
          label: "满减券",
          value: 2,
        },
        {
          label: "立减券",
          value: 3,
        },
      ],
      homeType: [
        {
          label: "首页",
          value: 1,
        },
        {
          label: "非首页",
          value: 0,
        },
      ],
      mainFileList: [], // 主图
      limitMainImg: 1, // 上传数量限制
      hideMainUpload: false, // 是否隐藏上传按钮
      show: false, //是否展示
      disabledGoods: true,
      // 分页配置
      pagination: {
        current: 1,
        size: 20,
        sizes: [10, 20, 50, 100],
        total: 0,
      },
      rulesCouponsForm: {
        supplyId: [
          { required: true, message: "请输入选择使用范围", trigger: "blur" },
        ],
        discountType: [
          { required: true, message: "请输入选择折扣类型", trigger: "blur" },
        ],
        homePage: [
          {
            required: true,
            message: "请输入选择是否为首页优惠券",
            trigger: "blur",
          },
        ],
        imgUrl: [
          { required: true, message: "请上传优惠券图片", trigger: "blur" },
        ],
        expiredDate: [
          { required: true, message: "请选择有效期", trigger: "blur" },
        ],
        discount: [
          { required: true, message: "请输入折扣", trigger: "blur" },
          { validator: checkDiscount, trigger: "blur" },
        ],
      },
    };
  },
  mounted() {
    this.getSupplyOptionsAll();
    // if (this.couponsForm.id) {
    // this.getOptionsCategory("");

    // }
  },
  computed: {},
  methods: {
    //获取优惠券
    getAllUserCoupons() {
      getAllUserCouponsApi(this.pagination.current, this.pagination.size).then(
        (res) => {
          this.couponsList = res.records;
          this.pagination.total = res.total;
          console.log(res.records);
        }
      );
    },
    /**
     * 获取优惠券使用范围列表
     */
    getSupplyOptionsAll() {
      // 获取所有分类(用于遍历商品类型数据分类对应名称)
      getAllSupplyGoodsApi().then((res) => {
        let ary = [];
        for (let i = 0; i < res.length; i++) {
          const item = {
            label: res[i].name,
            value: res[i].id,
          };
          ary.push(item);
        }
        this.optionsStore = ary;
      });
    },
    getOptionsCategory(goodsName) {
      // 根据门店获取
      getGoodsByNameApi(goodsName, this.couponsForm.supplyId).then((res) => {
        let ary = [];
        for (let i = 0; i < res.length; i++) {
          const item = {
            label: res[i].name,
            value: res[i].id,
          };
          ary.push(item);
        }
        this.goodsType = ary;
      });
    },
    //菜品搜索
    remoteMethod(query) {
      this.getOptionsCategory(query);
    },
    handleSelectChange(value) {
      const selectedOption = this.optionsStore.find(
        (item) => item.value === value
      );
      this.couponsForm.usedLimit = selectedOption.label;
    },
    //删除优惠券
    delCoupon(id) {
      this.$confirm("此操作将永久删除该优惠券, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        delCouponApi(id)
          .then((res) => {
            this.$message.success("删除成功!");
            this.getAllUserCoupons();
          })
          .catch((err) => {});
      });
    },
    //编辑优惠券
    async editCoupon(scope) {
      this.couponsForm = JSON.parse(JSON.stringify(scope.row));
      
      this.couponsForm.flag = this.couponsForm.flag + "";
      this.mainFileList.push({ url: this.couponsForm.imgUrl });
      if (this.couponsForm.usedGoodsId) {
        this.couponsForm.usedGoodsId = this.couponsForm.usedGoodsId * 1;
      }
      this.hideMainUpload = true;
      this.show = true;
    },
    /**
     * 文件上传
     */
    getUploadUrl() {
      return `${process.env.VUE_APP_URE}/file/uploadFile`;
    },
    // 上传之前的钩子
    uploadBefore(file) {
      this.loading = true;
      const isJPG = file.type === "image/jpeg";
      const isPNG = file.type === "image/png";
      if (!isJPG && !isPNG) {
        this.$message.error("上传图片只能是 JPG 或者 PNG 格式!");
      }
      return isJPG || isPNG;
    },
    // 上传成功回调
    uploadSuccessMain(file, fileList) {
      let obj = { url: file.data };
      this.mainFileList.push(obj); // push已经上传的图片
      this.couponsForm.imgUrl = file.data;
      if (this.mainFileList.length >= this.limitMainImg) {
        this.hideMainUpload = true; // 隐藏上传按钮
      }
      this.$refs.mainUpload.clearFiles(); // 清空上传的文件列表
    },

    // 移除文件
    uploadRemoveMain(file) {
      console.log(file);
      for (let i = 0; i < this.mainFileList.length; i++) {
        if (this.mainFileList[i].url == file.url) {
          this.mainFileList.splice(i, 1);
          this.couponsForm.imgUrl = null;
          this.hideMainUpload = false;
        }
      }
    },
    submitCoupon(couponsForm) {
      this.$refs[couponsForm].validate((valid) => {
        if (valid) {
          if (!this.couponsForm.id) {
            addCouponsApi(this.couponsForm).then((res) => {
              this.getAllUserCoupons();
              this.show = false;
              this.$message.success("添加成功!");
            });
          } else {
            putCouponApi(this.couponsForm).then((res) => {
              this.getAllUserCoupons();
              this.show = false;
              this.$message.success("修改成功!");
            });
          }
        }
      });
    },
    //格式化时间
    formatDate() {
      // 格式化函数
      if (this.couponsForm.expiredDate) {
        const date = new Date(this.couponsForm.expiredDate);
        const yyyy = date.getFullYear();
        const mm = String(date.getMonth() + 1).padStart(2, "0"); // 月份从0开始
        const dd = String(date.getDate()).padStart(2, "0");
        const hh = String(date.getHours()).padStart(2, "0");
        const min = String(date.getMinutes()).padStart(2, "0");
        const ss = String(date.getSeconds()).padStart(2, "0");

        // 将格式化结果赋值给 formattedDate
        this.couponsForm.expiredDate = `${yyyy}-${mm}-${dd} ${hh}:${min}:${ss}`;
      }
    },
    handleSizeChange(val){
        this.pagination.size=val
      },
      handleCurrentChange(val){
    this.pagination.current=val
      }
  },
  watch: {
    pagination: {
      handler() {
        this.getAllUserCoupons();
      },
      immediate: true,
      deep: true,
    },
    show(newVal) {
      if (!newVal) {
        this.couponsForm = {
          id: null,
          couponName: "",
          discountType: "",
          discount: "",
          number: 1,
          receivedNumber: 0,
          expiredDate: "",
          supplyId: "",
          homePage: "",
          flag: 0,
          usedGoodsId: null,
          imgUrl: null,
        };
        this.mainFileList = [];
        this.hideMainUpload = false;
      }
    },
    "couponsForm.supplyId": {
      handler(newVal, oldVal) {
        if (newVal == "") {
          this.disabledGoods = true;
        } else {
          if (oldVal) {
            this.couponsForm.usedGoodsId = null;
          }
          this.getOptionsCategory("");
          this.disabledGoods = false;
        }
      },
      immediate: true,
    },
  },
};
</script>

<style lang="less">
@import "@/assets/css/publicStyle.less";

.hide .el-upload--picture-card {
  display: none !important;
}

.wML-screen {
  display: block;
  width: 100%;
  user-select: none;
  margin-bottom: 20px;

  // 筛选列表
  .wMLS-list {
    display: block;
    width: 100%;
  }
}

// 商品列表区域
.wGoodsList-area {
  display: block;
  width: 100%;
  padding: 20px 16px;
  box-sizing: border-box;
  background-color: #ffffff;

  // 商品类别
  .wGL-categoryArea {
    display: flex;
    justify-content: space-between;

    .wGL-category {
      flex: none;
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      user-select: none;
      margin-bottom: 15px;

      .wGLC-item {
        flex: none;
        height: 40px;
        line-height: 40px;
        padding: 0 20px;
        margin-right: 10px;
        box-sizing: border-box;
        background-color: #f3f3f3;
        // border: 1px solid #999999;
        font-size: @wFontSize;
        cursor: pointer;
        transition: all 0.3s;

        &:hover,
        &.wActive {
          background-color: @wColor_theme;
          border-color: @wColor_theme;
          color: #ffffff;
        }
      }
    }
  }

  // 商品状态
  .wGL-state {
    display: flex;
    justify-content: space-between;
    align-items: center;
    user-select: none;
    margin-bottom: 15px;

    .wGLS-category {
      flex: none;
      display: flex;
      align-items: center;

      .wGLSC-btn {
        flex: none;
        margin-right: 15px;
        cursor: pointer;
        transition: all 0.3s;
        font-size: @wFontSize;
        color: #333333;

        &:hover,
        &.wActive {
          color: @wColor_theme;
          transition: all 0.3s;
        }
      }
    }

    .wGLS-operate {
      flex: none;
      display: flex;
      align-items: center;

      .wGLSO-btn {
        flex: none;
        margin-right: 7px;
        cursor: pointer;
        transition: all 0.3s;
        font-size: @wFontSize;
        color: #333333;

        &:hover {
          color: @wColor_theme;
          transition: all 0.3s;
        }
      }
    }
  }

  // 数据列表
  .wGL-goods {
    display: block;
    width: 100%;

    // table表
    .wGLG-table {
      display: block;
      width: 100%;
    }

    // 分页
    .wGLG-page {
      display: block;
      width: 100%;
      margin-top: 20px;
      text-align: center;
    }
  }
}

// 职务信息表单
.wDialogForm {
  display: block;
  width: 100%;
  height: 500px;
  position: relative;

  .wDialogForm-box {
    display: block;
    width: 100%;
    box-sizing: border-box;
  }
}

.specification {
  .el-form-item__content {
    display: flex !important;
  }
}
</style>
