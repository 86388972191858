<template>
    <div class="container">
        <!-- 数据总览 -->
        <div class="overview">
            <div class="overview-listA" @click="clickDetail({id:'', date:'all'})" style="background-color: #ff6600;">
                <div class="list-title" style="color:#fff">累计收入</div>
                <div class="list-info">
                    <span class="info-amount" style="color:#fff">￥ {{ detail.sumAmount }}</span>
                    <el-image style="width: 40px;" :src="require('@/assets/image/总收入.png')" fit="fit"></el-image>
                </div>
            </div>
            <div class="overview-listB" @click="clickDetail({id:'', date:'today'})">
                <div class="list-title">今日收入</div>
                <div class="list-info">
                    <span class="info-amount">￥ {{ detail.sumCurrentToday }}</span>
                    <el-image style="width: 40px;" :src="require('@/assets/image/日收入.png')" fit="fit"></el-image>
                </div>
            </div>
            <div class="overview-listC" @click="clickDetail({id:'', date:'month'})">
                <div class="list-title">本月收入</div>
                <div class="list-info">
                    <span class="info-amount">￥ {{ detail.sumCurrentMonth }}</span>
                    <el-image style="width: 40px;" :src="require('@/assets/image/月收入.png')" fit="fit"></el-image>
                </div>
            </div>
            <div class="overview-listD" @click="clickDetail({id:'', date:'year'})">
                <div class="list-title">本年收入</div>
                <div class="list-info">
                    <span class="info-amount">￥ {{ detail.sumCurrentYear }}</span>
                    <el-image style="width: 40px;" :src="require('@/assets/image/年收入.png')" fit="fit"></el-image>
                </div>
            </div>
        </div>
        <!-- 管理员 -->
        <div class="overview-store" v-if="showView">
            <div class="store-list" style="height: 630px;">
                <div class="top-row">
                    <div style="display:flex;align-items: center;">
                        <span class="row-title" style="margin-right: 10px;">选择门店</span>
                        <el-select v-model="optionsValue" @change="changeSelectStore" filterable clearable size="mini"
                            placeholder="请选或搜索择门店" style="width: 300px;">
                            <el-option v-for="item in optionsStore" :key="item.value" :data="item.data" :label="item.label" :value="{value:item.data}">
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <div class="body-col">
                    <div class="item-row">
                        <!-- <div class="row-box" style="background-color: #ff6600;">
                            <div class="box-title" style="color:#fff;">筛选结果（元）</div>
                            <div class="box-value" style="color:#fff;">{{detail.screenAmount}}</div>
                        </div> -->
                        <div class="row-box"  style="background-color: #ff6600;" @click="clickDetail({id:'store', date:'all'})">
                            <div class="box-title" style="color:#fff;">累计收入（元）</div>
                            <div class="box-value" style="color:#fff;">{{ detail.nowsumAmount }}</div>
                        </div>
                        <div class="row-box" @click="clickDetail({id:'store', date:'today'})">
                            <div class="box-title">今日收入（元）</div>
                            <div class="box-value">{{ detail.nowsumCurrentToday }}</div>
                        </div>
                        <div class="row-box" @click="clickDetail({id:'store', date:'month'})">
                            <div class="box-title">本月收入（元）</div>
                            <div class="box-value">{{ detail.nowsumCurrentMonth }}</div>
                        </div>
                        <div class="row-box" @click="clickDetail({id:'store', date:'year'})">
                            <div class="box-title">本年收入（元）</div>
                            <div class="box-value">{{ detail.nowsumCurrentYear }}</div>
                        </div>
                    </div>
                    <div class="item-row" style="margin-top: 20px;">
                        <div class="row-box" style="width: 100%;">
                            <div style="display:flex;flex-direction: row;align-items: center;height:80px;">
                                <div class="box-titles">门店名称：</div>
                                <div class="box-values">{{ detail.storeName }}</div>
                            </div>
                            <div style="display:flex;flex-direction: row;align-items: center;height:80px;">
                                <div class="box-titles">门店地址：</div>
                                <div class="box-values">{{ detail.storeAddress }}</div>
                            </div>
                            <div style="display:flex;flex-direction: row;align-items: center;height:80px;">
                                <div class="box-titles">门店电话：</div>
                                <div class="box-values">{{ detail.storePhone }}</div>
                            </div>
                            <div style="display:flex;flex-direction: row;align-items: center;height:80px;">
                                <div class="box-titles">门店账户：</div>
                                <div class="box-values">{{ detail.storeIdCard }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="store-list" style="">
                <div class="top-row">
                    <span class="row-title" style="margin-right: 10px;">收入明细</span>
                    <span style="margin-right: 10px;" v-if="attach">收入总额：{{attach}}</span>
                    <el-date-picker v-model="datePicker" @change="changeDatePicker" size="small" type="daterange"
                        align="right" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
                        :picker-options="datePickerOptions">
                    </el-date-picker>
                </div>
                <!-- <div>合计：{{price}}</div> -->
                <el-table :data="storeOrder" v-loading="loading" style="height: 528px;">
                    <el-table-column align="center" prop="id" label="订单编号" width="200"> </el-table-column>
                    <!-- <el-table-column align="center" prop="orderName" label="订单名称" > </el-table-column> -->
                    <el-table-column align="center" prop="tableName" label="桌名" width="100"> </el-table-column>
                    <el-table-column align="center" prop="userSnapshot.phone" label="用户" width="120"> </el-table-column>
                    <el-table-column align="center" prop="realMoney" label="金额" width="100"> </el-table-column>
                    <el-table-column align="center" prop="createTime" label="下单时间"> </el-table-column>
                </el-table>
                <div class="wPagination">
                    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                        :current-page="pages.current" :page-sizes="pages.sizes" :page-size="pages.size"
                        layout="total, prev, pager, next, jumper" :total="pages.total">
                    </el-pagination>
                </div>
            </div>
        </div>
        <!-- 门店 -->
        <div class="overview-store" v-else>
            <div class="" style="width:100%;background-color: #fff;padding:20px;border-radius: 5px;">
                <div class=""
                    style="display: flex; flex-direction: row; justify-content: space-between; align-items: center; margin-bottom: 20px;">
                    <span class="row-title" style="margin-right: 10px;">收入明细</span>
                    <el-date-picker v-model="datePicker" @change="changeDatePicker" size="small" type="daterange"
                        align="right" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
                        :picker-options="datePickerOptions">
                    </el-date-picker>
                </div>
                <el-table :data="storeOrder" v-loading="loading" style="height: 528px;">
                    <el-table-column align="center" prop="id" label="订单编号" width="200"> </el-table-column>
                    <el-table-column align="center" prop="orderName" label="订单名称" width="200"> </el-table-column>
                    <el-table-column align="center" prop="tableName" label="桌名" width="200"> </el-table-column>
                    <el-table-column align="center" prop="userSnapshot.phone" label="用户" width="200"> </el-table-column>
                    <el-table-column align="center" prop="realMoney" label="金额" width="200"> </el-table-column>
                    <el-table-column align="center" prop="createTime" label="下单时间"> </el-table-column>
                </el-table>
                <div class="wPagination">
                    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                        :current-page="pages.current" :page-sizes="pages.sizes" :page-size="pages.size"
                        layout="total, sizes, prev, pager, next, jumper" :total="pages.total">
                    </el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { getStoreAmountApi, getStoreListApi, orderListByStore } from "@/network/wApi.js";
export default {

    data() {
        return {
            // 日期选择值
            datePicker: [new Date(), new Date()],
            // datePicker: [],
            // 日期选择器
            datePickerOptions: {
                shortcuts: [{
                    text: '最近一周',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近一个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近三个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                        picker.$emit('pick', [start, end]);
                    }
                }]
            },
            // 订单明细列表
            storeOrder: [],
            loading: false,
            // 分页
            pages: {
                current: 1,
                sizes: [10],
                size: 10,
                total: 0,
            },
            // 门店选择下拉框
            optionsStore: [],
            optionsValue: "",
            // 查询data
            detail: {
                // header data
                sumAmount: 0,       //所有门店累计收入
                sumCurrentToday: 0, //所有门店今日收入
                sumCurrentMonth: 0, //所有门店本月收入
                sumCurrentYear: 0,  //所有门店本年收入
                // now store data

                nowsumAmount: 0,       //当前门店累计收入
                nowsumCurrentToday: 0, //当前门店今日收入
                nowsumCurrentMonth: 0, //当前门店本月收入
                nowsumCurrentYear: 0,  //当前门店本年收入

                screenAmount: 0.00,          //当前筛选
                storeName: "",
                storeAddress: "",
                storePhone: "",
                storeIdCard: "",
                storeId: ""
            },
            // 权限处理
            permission: {
                storeId: "",
                roleId: ""
            },
            showView: true,
            // 开始时间
            startTime: '',
            endTime: '',
            price:"",
            //收入总额
            attach:0

        }
    },
    mounted() {
        // 门店下拉框赋值
        this.getOptionsStore()
        // 门店累计、日、月、年收入
        this.getStoreOverview()
        // 获取当前日期
        this.getDateYMD()
        // this.getStoreOrderDetail()
    },
    computed: {

    },
    methods: {
        clickDetail(e){
            // console.log(e)
            let date = new Date()
            if (e.id == '' && !this.showView){
                const obj = {
                    value:{
                        id: JSON.parse(localStorage.getItem('USER_INFO')).storeId
                    }
                }
                if (e.date == 'all') {
                    this.datePicker = []
                    this.startTime = ""
                    this.endTime = ""
                    this.getStoreOrderDetail(obj)
                }
                if (e.date == 'today') {
                    this.datePicker = [new Date(), new Date()]
                    this.startTime = this.datePicker[0]
                    this.endTime = this.datePicker[1]
                    this.getStoreOrderDetail(obj)
                }
                if (e.date == 'month') {
                    this.datePicker = [new Date(date.getFullYear(), date.getMonth(), 1), new Date(date.getFullYear(), date.getMonth() + 1, 0)]
                    this.startTime = this.datePicker[0]
                    this.endTime = this.datePicker[1]
                    this.getStoreOrderDetail(obj)
                }
                if (e.date == 'year') {
                    this.datePicker = [new Date(date.getFullYear(), 0), new Date(date.getFullYear(), 11 , 31) ]
                    this.startTime = this.datePicker[0]
                    this.endTime = this.datePicker[1]
                    this.getStoreOrderDetail(obj)
                }

            } else if (e.id == '' && this.showView) {
                this.optionsValue = ""
                this.detail.storeName = ""
                this.detail.storeIdCard = ""
                this.detail.storeAddress = ""
                this.detail.storePhone = ""
                this.detail.storeId = ""
                this.detail.nowsumAmount = ""
                this.detail.nowsumCurrentToday = ""
                this.detail.nowsumCurrentMonth = ""
                this.detail.nowsumCurrentYear = ""
                if (e.date == 'all') {
                    this.datePicker = []
                    this.startTime = ""
                    this.endTime = ""
                    this.getStoreOrderDetail()
                }
                if (e.date == 'today') {
                    this.datePicker = [new Date(), new Date()]
                    this.startTime = this.datePicker[0]
                    this.endTime = this.datePicker[1]
                    this.getStoreOrderDetail()
                }
                if (e.date == 'month') {
                    this.datePicker = [new Date(date.getFullYear(), date.getMonth(), 1), new Date(date.getFullYear(), date.getMonth() + 1, 0)]
                    this.startTime = this.datePicker[0]
                    this.endTime = this.datePicker[1]
                    this.getStoreOrderDetail()
                }
                if (e.date == 'year') {
                    this.datePicker = [new Date(date.getFullYear(), 0), new Date(date.getFullYear(), 11 , 31) ]
                    this.startTime = this.datePicker[0]
                    this.endTime = this.datePicker[1]
                    this.getStoreOrderDetail()
                }
            } else if (e.id == 'store' && this.detail.storeId) {
                const obj = {
                    value:{
                        id: this.detail.storeId 
                    }
                }
                if (e.date == 'all') {
                    this.datePicker = []
                    this.startTime = ""
                    this.endTime = ""
                    this.getStoreOrderDetail(obj)
                }
                if (e.date == 'today') {
                    this.datePicker = [new Date(), new Date()]
                    this.startTime = this.datePicker[0]
                    this.endTime = this.datePicker[1]
                    this.getStoreOrderDetail(obj)
                }
                if (e.date == 'month') {
                    this.datePicker = [new Date(date.getFullYear(), date.getMonth(), 1), new Date(date.getFullYear(), date.getMonth() + 1, 0)]
                    this.startTime = this.datePicker[0]
                    this.endTime = this.datePicker[1]
                    this.getStoreOrderDetail(obj)
                }
                if (e.date == 'year') {
                    this.datePicker = [new Date(date.getFullYear(), 0), new Date(date.getFullYear(), 11 , 31) ]
                    this.startTime = this.datePicker[0]
                    this.endTime = this.datePicker[1]
                    this.getStoreOrderDetail(obj)
                }
            }
        },
        // 默认选中一个门店
        defaultSelectStore(e){
            this.optionsValue = e.store_name
            this.detail.storeName = e.store_name
            this.detail.storeIdCard = e.bank_card
            this.detail.storeAddress = e.address
            this.detail.storePhone = e.tel
            this.detail.storeId = e.id
            // 查询门店年月日累计收入
            this.getStoreAmount({ id: e.id }, res => {
                this.detail.nowsumAmount = res.sum
                this.detail.nowsumCurrentToday = res.today
                this.detail.nowsumCurrentMonth = res.month
                this.detail.nowsumCurrentYear = res.year
            })
        },
        // 获取所有门店信息
        getOptionsStore() {
            getStoreListApi({ atype: '1' }).then(res => {
                console.log("门店数据", res)
                let ary = []
                for (let i = 0; i < res.length; i++) {
                    const obj = {
                        label: res[i].store_name,
                        value: res[i].id,
                        data: res[i]
                    }
                    ary.push(obj)
                }
                this.optionsStore = ary
                this.defaultSelectStore(res[0])
                console.log("管理执行")
            })
        },
        // 选择门店
        changeSelectStore(e) {
            if (e) {                
                this.detail.storeName = e.value.store_name
                this.detail.storeIdCard = e.value.bank_card
                this.detail.storeAddress = e.value.address
                this.detail.storePhone = e.value.tel
                this.detail.storeId = e.value.id
                // 查询门店年月日累计收入
                this.getStoreAmount({ id: e.value.id }, res => {
                    this.detail.nowsumAmount = res.sum
                    this.detail.nowsumCurrentToday = res.today
                    this.detail.nowsumCurrentMonth = res.month
                    this.detail.nowsumCurrentYear = res.year
                })
                this.getStoreOrderDetail(e)

            } else {
                this.detail.storeName          = ""
                this.detail.storeIdCard        = ""
                this.detail.storeAddress       = ""
                this.detail.storePhone         = ""
                this.detail.storeId            = ""
                this.detail.nowsumAmount       = ""
                this.detail.nowsumCurrentToday = ""
                this.detail.nowsumCurrentMonth = ""
                this.detail.nowsumCurrentYear  = ""
                this.getStoreOrderDetail()
            }
        },

        // 获取所有门店年月日累计收入
        getStoreAmount(e, callback) {
            const data = {
                storeId: e.id || "",
                atype: "1"
            }
            getStoreAmountApi(data).then(res => {
                callback(res)
            })
        },
        getStoreOverview() {
            // 获取当前账号登录信息
            const userInfo = JSON.parse(localStorage.getItem('USER_INFO'))
            if (userInfo.roleId == 7) {
                console.log("门店账户")
                // this.detail.storeId = userInfo.storeId
                // 隐藏管理员视图
                this.showView = false
                // 查询门店年月日累计收入
                this.getStoreAmount({ id: userInfo.storeId }, res => {
                    this.detail.sumAmount = res.sum
                    this.detail.sumCurrentToday = res.today
                    this.detail.sumCurrentMonth = res.month
                    this.detail.sumCurrentYear = res.year
                })
            } else {
                console.log("其他账户")
                // 查询门店年月日累计收入
                this.getStoreAmount({ id: '' }, res => {
                    this.detail.sumAmount = res.sum
                    this.detail.sumCurrentToday = res.today
                    this.detail.sumCurrentMonth = res.month
                    this.detail.sumCurrentYear = res.year
                })
            }
        },

        // 获取当前时间
        getDateYMD() {
            let date = new Date()
            let year = date.getFullYear()
            let month = date.getMonth() + 1
            let day = date.getDate()
            if (month < 10) {
                month = "0" + month
            }
            if (day < 10) {
                day = "0" + day
            }
            this.startTime = year + '-' + month + '-' + day
            this.endTime = year + '-' + month + '-' + day
            // console.log(this.startTime)
            this.getStoreOrderDetail()
        },

        // 获取门店订单明细
        getStoreOrderDetail(e) {
            // console.log(this.detail)
            let obj = {}
            obj["start"] = this.startTime
            obj["end"] = this.endTime
            obj["pageModel.pageNo"] = this.pages.current
            obj["pageModel.pageSize"] = this.pages.size
            obj["pageModel.sortField"] = "createTime"
            obj["pageModel.sortWay"] = "desc"
            obj["state"] = "11"

            if (e) {
                const userInfo = JSON.parse(localStorage.getItem('USER_INFO'))
                if(userInfo.storeId){
                    obj["storeId"] = userInfo.storeId
                }else{
                    obj["storeId"] = e.value.id
                }
                // console.log(userInfo);
            } else {
                obj["storeId"] = ""
            }
            obj["atype"] = 1
            console.log(obj)
            orderListByStore(obj).then(res => {
                // console.log('门店',res.records)
                this.storeOrder = res.records
                this.pages.total = res.total
               
                this.attach=res.countId
               
            })
        },

        // 门店总览列表门店选择事件 
        // selectStore(e) {
        //     this.detail.storeName = e.store_name
        //     this.detail.storeAddress = e.address
        //     this.detail.storePhone = e.tel
        //     this.detail.storeAmount = e.today
        //     this.detail.storeIdCard = e.bank_card
        //     this.detail.screenAmount = e.today
        //     // 获取门店 年月日累计金额
        //     const data = {
        //         storeId: e.id,
        //         atype: "1"
        //     }
        //     getStoreAmountApi(data).then(res => {
        //         this.detail.nowsumAmount = res.sum
        //         this.detail.nowsumCurrentToday = res.today
        //         this.detail.nowsumCurrentMonth = res.month
        //         this.detail.nowsumCurrentYear = res.year
        //     })
        // },

        // 门店时间选择器
        changeDatePicker(e) {
            if (e) {
                const start = e[0]
                const end = e[1]
                const startMM = start.getMonth() < 9 ? "0" + (start.getMonth() + 1) : start.getMonth() + 1;
                const startDD = start.getDate() <= 9 ? "0" + start.getDate() : start.getDate();
                const endMM = end.getMonth() < 9 ? "0" + (end.getMonth() + 1) : end.getMonth() + 1;
                const endDD = end.getDate() <= 9 ? "0" + end.getDate() : end.getDate();
                const startDate = start.getFullYear() + '-' + startMM + '-' + startDD
                const endDate = end.getFullYear() + '-' + endMM + '-' + endDD
                // console.log(startDate + ' ' + endDate)
                this.startTime = startDate
                this.endTime = endDate
                const data = {
                    atype: "1",
                    start: startDate,
                    end: endDate
                }
                getStoreListApi(data).then(res => {
                    for (let i = 0; i < res.length; i++) {
                        if (this.detail.storeName == res[i].store_name) {
                            this.detail.screenAmount = res[i].today;
                        }
                    }
                })

                // 获取门店订单明细
                if (this.detail.storeId) {
                    this.getStoreOrderDetail({value:{id:this.detail.storeId}})
                } else {
                    this.getStoreOrderDetail()
                }
                
            } else {
                this.startTime = ""
                this.endTime = ""

                // 获取门店订单明细
                if (this.detail.storeId) {
                    this.getStoreOrderDetail({value:{id:this.detail.storeId}})
                } else {
                    this.getStoreOrderDetail()
                }
            }
        },

        // 处理分页数据
        // processingPaging() {
        //     this.loading = false,
        //     this.table = []
        //     const ary = this.arrayData
        //     const num = (this.pages.current * this.pages.size) - (this.pages.size)
        //     let rem = Math.floor(ary.length / this.pages.size)
        //     const mod = ary.length % this.pages.size
        //     // console.log("页面总数",rem)
        //     // console.log("页面余数",mod)
        //     // console.log("取值范围",num)
        //     // console.log("总长度",ary.length )
        //     // console.log("当前显示条数",this.pages.size )
        //     // console.log("当前在第几页",this.pages.current)
        //     if (mod != 0) { rem++ }
        //     if (this.pages.current == 1) {
        //         if (ary.length < this.pages.size) {
        //             for (let i = 0; i < ary.length; i++) {
        //                 this.table.push(ary[i])
        //             }
        //         } else {
        //             for (let i = 0; i < this.pages.size; i++) {
        //                 this.table.push(ary[i])
        //             }
        //         }
        //         return
        //     }
        //     if (this.pages.current == rem) {
        //         for (let i = num; i < ary.length; i++) {
        //             this.table.push(ary[i])
        //         }
        //         return
        //     }
        //     if (this.pages.current != 1 && this.pages.current != rem) {
        //         for (let i = num; i < this.pages.size + num; i++) {
        //             this.table.push(ary[i])
        //         }
        //         return
        //     }
        // },

        // 当前页面显示多少条数据
        handleSizeChange(e) {
            const obj = {
                value:{
                    id: this.detail.storeId 
                }
            }
            this.pages.size = e
            this.pages.current = 1
            // this.processingPaging()
            this.getStoreOrderDetail(obj)
        },
        // 跳转第n页
        handleCurrentChange(e) {
            console.log("2",this.detail.storeId)
            const obj = {
                value:{
                    id: this.detail.storeId 
                }
            }
            this.pages.current = e
            // this.processingPaging()
            this.getStoreOrderDetail(obj)
        }
    }
}
</script>
<style lang='less' scoped>
@import "@/assets/css/publicStyle.less";

.container {
    .overview {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        .overview-listA:hover{
            background-color: rgb(233, 233, 233);
        }
        .overview-listB:hover{
            background-color: rgb(233, 233, 233);
        }
        .overview-listC:hover{
            background-color: rgb(233, 233, 233);
        }
        .overview-listD:hover{
            background-color: rgb(233, 233, 233);
        }
        .overview-listA,
        .overview-listB,
        .overview-listC,
        .overview-listD {
            background-color: #fff;
            width: calc((100%)/4 - 60px);
            border-radius: 5px;
            padding: 20px;

            .list-title {
                font-size: 14px;
            }

            .list-info {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                height: 50px;

                .info-amount {
                    font-size: 25px;
                    font-weight: bold;
                    color: #ff6600;
                }
            }
        }

    }

    .overview-store {
        margin-top: 20px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;

        .store-list {
            background-color: #fff;
            border-radius: 5px;

            width: calc((100%)/2 - 54px);
            padding: 20px;

            .top-row {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 20px;

                .row-title {
                    font-size: 20px;
                }
            }

            .body-col {
                display: flex;
                flex-direction: column;
                margin-top: 20px;

                .item-row {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    width: 100%;
                    .row-box:hover{
                        background-color: rgb(233, 233, 233);
                    }
                    .row-box {
                        width: calc((100%)/4 - 50px);
                        border: 1px solid #dddddd;
                        border-radius: 10px;
                        padding: 20px;

                        .box-title {
                            font-size: 14px;
                            color: #888888;
                        }

                        .box-value {
                            padding-top: 10px;
                            font-size: 20px;
                            font-weight: bold;
                        }

                        .box-titles {
                            font-size: 15px;
                            font-weight: bold;

                        }

                        .box-values {
                            font-size: 14px;
                            color: #888888;
                            margin-left: 10px;
                        }
                    }
                }
            }
        }
    }
}</style>