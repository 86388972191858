<template>
    <!-- 广告列表 -->
    <div>
      <!-- 筛选查询 -->
      <div class="wScreenArea">
        <el-card class="box-card">
          <div class="wScreenCardHeader">
            <span></span>
            <el-button type="primary" size="mini" @click="show = true"
              >添加</el-button
            >
          </div>
        </el-card>
      </div>
      <!-- 新增、修改版banner弹框 -->
      <el-dialog
        width="1000px"
        :title="advertisementForm.id ? '修改菜品广告' : '添加菜品广告'"
        :visible.sync="show"
        :append-to-body="true"
        :close-on-click-modal="false"
      >
        <!-- 表单 -->
        <el-form
          :rules="rulesAdvertisementForm"
          :model="advertisementForm"
          ref="advertisementForm"
          label-width="120px"
          @submit.native.prevent
        >
          <el-row :gutter="24">
            <el-col :span="12">
              <el-form-item label="广告名称" prop="adName">
                <el-input
                  v-model="advertisementForm.adName"
                  type="text"
                  placeholder="请输入广告名称"
                  maxlength="30"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="广告描述" prop="description">
                <el-input
                  v-model="advertisementForm.description"
                  type="textarea"
                  placeholder="请输入广告描述"
                  maxlength="30"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="24">
            <el-col :span="12">
              <el-form-item label="广告类型" prop="atype">
                <el-select
                  v-model="advertisementForm.atype"
                  placeholder="请选择范围"
                  clearable
                  style="width: 100%"
                >
                  <el-option
                    v-for="(item, index) in optionsStore"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="商品名称" prop="goodsId">
                <el-select
                  filterable
                  remote
                  reserve-keyword
                  v-model="advertisementForm.goodsId"
                  :remote-method="remoteMethod"
                  placeholder="请选择商品"
                  clearable
                   :disabled="disabledGoods"
                  style="width: 100%"
                >
                  <el-option
                    v-for="(item, index) in goodsType"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
    
          <el-row :gutter="24">
            <el-col :span="12">
              <el-form-item label="展示时间" prop="showTime">
                <el-input-number v-model="advertisementForm.showTime" :min="1" label="输入展示时间"></el-input-number>
              </el-form-item>
            </el-col>
            <el-col :span="12">
             
              <el-form-item label="广告内容" prop="adUri">
                <el-upload ref="mainUpload" list-type="picture-card" :limit="limitMainImg" :file-list="mainFileList"
                  :class="{ hide: hideMainUpload }" :action="getUploadUri()" :before-upload="uploadBefore"
                  :on-success="uploadSuccessMain" :on-remove="uploadRemoveMain" :show-file-list="true">
                  <i class="el-icon-plus"></i>
                </el-upload>
              </el-form-item>
  
            </el-col>
          </el-row>
          <el-row :gutter="24">
        
            <el-col :span="12">
              <el-form-item
          label="是否启用："
          prop="ban"
        >
  <el-switch
    v-model="advertisementForm.bannedFlag"
    active-color="#ff4949"
    inactive-color="#DCDFE6"
    active-value="0"
    inactive-value="1">
  </el-switch>
        </el-form-item>
            </el-col>
          </el-row>
         
    
        </el-form>
        <!-- footer -->
        <div slot="footer">
          <el-button @click="show = false">取 消</el-button>
          <el-button type="primary" @click="submitCoupon('advertisementForm')">确 定</el-button>
        </div>
      </el-dialog>
      <!-- 表格数据 -->
      <div class="wTableArea">
        <!-- 订单列表 -->
        <template>
          <!-- 表格 -->
          <el-table border :data="advertisementList">
            <el-table-column
              prop="adName"
              label="广告名称"
              align="center"
              width="100"
            >
            </el-table-column>
            <el-table-column label="图片" align="center">
              <template slot-scope="scope">
                <el-image
                  style="width: 180px; height: 100px"
                  fit="cover"
                  :src="scope.row.adUri"
                  :preview-src-list="[scope.row.adUri]"
                ></el-image>
              </template>
            </el-table-column>
            <el-table-column
            prop="description"
            label="描述"
            align="center"
            width="100"
          >
          </el-table-column>
            <el-table-column
              prop="atypeValue"
              label="广告类型"
              align="center"
              width="100"
            >
            </el-table-column>
            <el-table-column
              prop="atypeValue"
              label="商品名称"
              align="center"
              width="100"
            >
            </el-table-column>
           
            <el-table-column prop="isUsed" label="状态" align="center">
              <template slot-scope="scope">
                <div v-if="scope.row.bannedFlag == 1">禁用</div>
                <div v-else-if="scope.row.bannedFlag == 0">启用</div>
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center">
              <template slot-scope="scope">
                <el-button type="text" @click="editCoupon(scope)"
                  ><span>编辑</span></el-button
                >
                <el-button
                  type="text"
                  @click="delCoupon(scope.row.id)"
                  ><span>删除</span></el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </template>
        <!-- 分页 -->
        <div class="wPagination" >
          <div class="wPagination">
            <el-pagination
               @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
              :current-page="pagination.current"
              :page-sizes="pagination.sizes"
              :page-size="pagination.size"
              :total="pagination.total"
              layout="total, sizes, prev, pager, next, jumper"
            >
            </el-pagination>
          </div>
        </div>
      </div>
      <!-- 分页 -->
    </div>
  </template>
  
  <script>
  import {
    getAllAdvertisementApi,
    addAdvertisementApi,
    getAllSupplyGoodsApi,
    getGoodsByNameApi,
    delAdvertisementApi,
    putAdvertisementApi
  } from "@/network/api";
  
  export default {
    name: "OperateAdvertisement",
    data() {
      return {
        advertisementList: [],
        advertisementForm: {
          id: null,
          adName: "",
          description: "",
          showTime: 1,
          atype: "",
          bannedFlag: '0',
          goodsId: null,
          adUri: null,
        },
        // 广告可使用类型options
        optionsStore: [],
        // 广告所属菜品
        goodsType: [],
        mainFileList: [],         // 主图
        limitMainImg: 1,          // 上传数量限制
        hideMainUpload: false,    // 是否隐藏上传按钮
        show: false, //是否展示
        // 分页配置
        pagination: {
          current: 1,
          size: 20,
          sizes: [10, 20, 50, 100],
          total: 0,
        },
        rulesAdvertisementForm: {
          atype: [
            { required: true, message: "请选择广告类型", trigger: "blur" },
          ],
          adName: [
            { required: true, message: "请输入广告名称", trigger: "blur" },
          ],
          adUri: [
            { required: true, message: "请上传广告图片", trigger: "blur" },
          ],
        
      
        },
      };
    },
    mounted() {
      this.getSupplyOptionsAll();
     
    },
    computed: {},
    methods: {
      //获取菜品广告
      getAllAdvertisement() {
        getAllAdvertisementApi({pageModel:{pageNo:this.pagination.current, pageSize:this.pagination.size}}).then(
          (res) => {
            this.advertisementList = res.records;
            this.pagination.total = res.total;
          }
        );
      },
      /**
       * 获取广告广告类型列表
       */
      getSupplyOptionsAll() {
        // 获取所有分类(用于遍历商品类型数据分类对应名称)
        getAllSupplyGoodsApi().then((res) => {
          let ary = [];
          for (let i = 0; i < res.length; i++) {
            const item = {
              label: res[i].name,
              value: res[i].id,
            };
            ary.push(item);
          }
          this.optionsStore = ary;
        });
      },
      getOptionsCategory(goodsName) {
        // 根据门店获取
        getGoodsByNameApi(goodsName,this.advertisementForm.atype).then((res) => {
          let ary = [];
          for (let i = 0; i < res.length; i++) {
            const item = {
              label: res[i].name,
              value: res[i].id,
            };
            ary.push(item);
          }
          this.goodsType = ary;
        });
      },
      //菜品搜索
      remoteMethod(query) {
        this.getOptionsCategory(query);
      },
      //删除广告
      delCoupon(id){
        this.$confirm("此操作将永久删除该广告, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
          .then(() => {
            delAdvertisementApi(id).then(res=>{
              this.$message.success("删除成功!");
          this.getAllAdvertisement()
        })
            .catch(err => {});
          })
      
        
      },
      //编辑广告
      editCoupon(scope){
        this.advertisementForm=JSON.parse(JSON.stringify(scope.row))
        this.mainFileList.push({url:this.advertisementForm.adUri})
         this.advertisementForm.bannedFlag=this.advertisementForm.bannedFlag+""
       
         this.hideMainUpload=true
        this.show=true
      },
        /**
       * 文件上传
      */
      getUploadUri() { return `${process.env.VUE_APP_URE}/file/uploadFile` },
      // 上传之前的钩子
      uploadBefore(file) {
        this.loading = true
        const isJPG = file.type === 'image/jpeg'
        const isPNG = file.type === 'image/png'
        if (!isJPG && !isPNG) {
          this.$message.error('上传图片只能是 JPG 或者 PNG 格式!')
        }
        return isJPG || isPNG
      },
      // 上传成功回调
      uploadSuccessMain(file, fileList) {
        let obj = { url: file.data }
        this.mainFileList.push(obj)           // push已经上传的图片
        this.advertisementForm.adUri = file.data
        if (this.mainFileList.length >= this.limitMainImg) {
          this.hideMainUpload = true          // 隐藏上传按钮
        }
        this.$refs.mainUpload.clearFiles()    // 清空上传的文件列表
      },
     
      // 移除文件
      uploadRemoveMain(file) {
        console.log(file)
        for (let i = 0; i < this.mainFileList.length; i++) {
          if (this.mainFileList[i].url == file.url) {
            this.mainFileList.splice(i, 1)
            this.advertisementForm.adUri = null
            this.hideMainUpload = false
          }
        }
      },
      submitCoupon(advertisementForm){
        this.$refs[advertisementForm].validate(valid=>{
          if (valid) {
            if (!this.advertisementForm.id) {
                addAdvertisementApi(this.advertisementForm).then(res=>{
               
                  this.getAllAdvertisement()
                this.show=false
                this.$message.success("添加成功!");
              })
    
        }else{
          putAdvertisementApi(this.advertisementForm).then(res=>{
            console.log(res);
              this.getAllAdvertisement()
              this.show=false
              this.$message.success("修改成功!");
            })
          }
       
          }
         
        })
       
      },
      handleSizeChange(val){
        this.pagination.size=val
      },
      handleCurrentChange(val){
    this.pagination.current=val
      }
    },
    watch: {
      pagination: {
        handler() {
          console.log(222);
          this.getAllAdvertisement();
        },
        immediate: true,
        deep: true,
      },
      show(newVal) {
        if (!newVal) {
          this.advertisementForm = {
            id: null,
            adName: "",
            showTime: 1,
            atype: "",
            bannedFlag: '0',
            goodsId: null,
            adUri: null,
          };
          this.mainFileList=[]
          this.hideMainUpload=false
        }
      },
      "advertisementForm.atype": {
      handler(newVal, oldVal) {
        if (newVal == "") {
          this.disabledGoods = true;
        } else {
          if (oldVal) {
            this.advertisementForm.goodsId = null;
          }
          this.getOptionsCategory("");
          this.disabledGoods = false;
        }
      },
      immediate: true,
    },
      
    },
  };
  </script>
  
  <style lang="less">
  @import "@/assets/css/publicStyle.less";
  
  .hide .el-upload--picture-card {
    display: none !important;
  }
  
  .wML-screen {
    display: block;
    width: 100%;
    user-select: none;
    margin-bottom: 20px;
  
    // 筛选列表
    .wMLS-list {
      display: block;
      width: 100%;
    }
  }
  
  // 商品列表区域
  .wGoodsList-area {
    display: block;
    width: 100%;
    padding: 20px 16px;
    box-sizing: border-box;
    background-color: #ffffff;
  
    // 商品类别
    .wGL-categoryArea {
      display: flex;
      justify-content: space-between;
  
      .wGL-category {
        flex: none;
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        user-select: none;
        margin-bottom: 15px;
  
        .wGLC-item {
          flex: none;
          height: 40px;
          line-height: 40px;
          padding: 0 20px;
          margin-right: 10px;
          box-sizing: border-box;
          background-color: #f3f3f3;
          // border: 1px solid #999999;
          font-size: @wFontSize;
          cursor: pointer;
          transition: all 0.3s;
  
          &:hover,
          &.wActive {
            background-color: @wColor_theme;
            border-color: @wColor_theme;
            color: #ffffff;
          }
        }
      }
    }
  
    // 商品状态
    .wGL-state {
      display: flex;
      justify-content: space-between;
      align-items: center;
      user-select: none;
      margin-bottom: 15px;
  
      .wGLS-category {
        flex: none;
        display: flex;
        align-items: center;
  
        .wGLSC-btn {
          flex: none;
          margin-right: 15px;
          cursor: pointer;
          transition: all 0.3s;
          font-size: @wFontSize;
          color: #333333;
  
          &:hover,
          &.wActive {
            color: @wColor_theme;
            transition: all 0.3s;
          }
        }
      }
  
      .wGLS-operate {
        flex: none;
        display: flex;
        align-items: center;
  
        .wGLSO-btn {
          flex: none;
          margin-right: 7px;
          cursor: pointer;
          transition: all 0.3s;
          font-size: @wFontSize;
          color: #333333;
  
          &:hover {
            color: @wColor_theme;
            transition: all 0.3s;
          }
        }
      }
    }
  
    // 数据列表
    .wGL-goods {
      display: block;
      width: 100%;
  
      // table表
      .wGLG-table {
        display: block;
        width: 100%;
      }
  
      // 分页
      .wGLG-page {
        display: block;
        width: 100%;
        margin-top: 20px;
        text-align: center;
      }
    }
  }
  
  // 职务信息表单
  .wDialogForm {
    display: block;
    width: 100%;
    height: 500px;
    position: relative;
  
    .wDialogForm-box {
      display: block;
      width: 100%;
      box-sizing: border-box;
    }
  }
  
  .specification {
    .el-form-item__content {
      display: flex !important;
    }
  }
  </style>
  